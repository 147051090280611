import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jordan-tpz/Workspace/galidog-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "natural-language-processing"
    }}>{`Natural Language Processing`}</h1>
    <h2 {...{
      "id": "préambule"
    }}>{`Préambule`}</h2>
    <p>{`Le but premier de l'application mobile Galidog étant de proposer un outil d'aide à la personne au travers d'une assistance vocale « à la Ok Google », l'équipe de `}<a parentName="p" {...{
        "href": "https://www.troispointzero.fr/"
      }}>{`TroisPointZéro`}</a>{` a utilisé un système de compréhension du langage naturel (le « Natural Language Processing ») entièrement « fait maison ».`}</p>
    <p>{`En effet, ce dernier est capable d'intercepter les mots prononcés par l'utilisateur depuis l'application mobile, de les comprendre, et de retourner une réponse en fonction de ce qu'il a pu analyser.`}</p>
    <blockquote>
      <p parentName="blockquote">{`La réponse retournée par l'algorithme est traitée afin d'effectuer le « dispatch » des actions à réaliser selon la commande prononcée.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      